import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import init, {init2} from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import PielersForm from '../../../../@WUM/core/form-serilizer'
import configuration from './configuration/Page/configuration'
import Spinner from 'react-bootstrap/Spinner'
import {ResponseAllUser, ResponseDetail} from '../API/response/member/member'
import { memberApiType } from '../API/request/member/memberApi.interface'
import { member } from '../API/request/member/member'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'
import {ApiURL, error, IntlMessage, IntlMessageValue} from '../../../../@WUM/core/Elements/index'
import {getToken, handleSubmitForm, setNavi} from '../../../../@WUM/core/Function/index'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {ResponseUSerProfil} from "../../profil/API/response/support_software";
import {_UserInterface} from "../../profil/API/request/SupportSoftwareApi.interface";
import {support_software} from "../../profil/API/request/support_software";
import {
    FileUploadButton, Regex,
    UniButton,
    UniCards,
    UniCol,
    UniForm,
    UniRow,
    UniSpinner
} from "../../../../@WUM/core/Elements";
import Stats from "../../../../@WUM/core/Elements/Stats";
import ToggleButton from "react-bootstrap/ToggleButton";
import Country, {enabledFederalState} from "../../../../@WUM/core/Elements/Country";
import {CryptDecrypt, CryptEncrypt} from "../../../../@WUM/core/Elements/crypt/Crypt";
import Card from "react-bootstrap/Card";
import {fetchDataApiUser} from "../../../../@WUM/core/RestFullApi/ApiHook";

const MemberDetail = (value: any) => {
    const { id } = useParams<{ id: string }>()
    const Profils = ResponseDetail(id);
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    // @ts-ignore
    let Profil:_UserInterface[] = [Profils !== undefined ? Profils: undefined]
    /**
     * Neuladen der Seite
     */
    const routeChange = (path: string) => {
        let paths = path
        history(paths)
    }
    const [currentTutorial, setCurrentTutorial] = useState<_UserInterface[]>(
        typeof Profil !== "boolean" && Profil !== undefined ? Profil : []
    )
    const Send = (event:any) => {
        event.preventDefault()
        event.stopPropagation()

        /*
               *  Wichtig zum Rausholen der Datan aus Formular und senden
               *  Das Interface muss immer angepasst werden
               */
        let Data = init()

        let reauestData: _UserInterface[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        member
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                routeChange(`/Member/Detail/`+id )
            })
            .catch((e:any) => {
                error(e)
            })
    }

    const SendRights = (e:any) => {

        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "TaskManager": {
                    "Dashboard": (Data.TaskManager_Dashboard === 'on' ? 1: 0),
                    "Projects": (Data.TaskManager_Projects === 'on' ? 1: 0),
                    "Mytask": (Data.TaskManager_Mytask === 'on' ? 1: 0),
                    "Settings": (Data.TaskManager_Settings === 'on' ? 1: 0),
                    "Reports": (Data.TaskManager_Reports === 'on' ? 1: 0),
                    "ProjekManager": 0,
                    "ServiceTracking": (Data.TaskManager_ServiceTracking === 'on' ? 1: 0)
                },
                "Ticketsystem": {
                    "Dashboard": (Data.Ticketsystem_Dashboard === 'on' ? 1: 0),
                    "Tickets": (Data.Ticketsystem_Tickets === 'on' ? 1: 0),
                    "Settings": (Data.Ticketsystem_Settings === 'on' ? 1: 0),
                    "Reports": (Data.Ticketsystem_Reports === 'on' ? 1: 0),
                    "Organisation": (Data.Ticketsystem_Organisation === 'on' ? 1: 0),
                    "ProjekManager": 0
                },
                "General": {
                    "Comapny": (Data.General_Comapny === 'on' ? 1: 0),
                    "PostOffice": (Data.General_PostOffice === 'on' ? 1: 0),
                    "stats": (Data.General_stats === 'on' ? 1: 0)
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/permission/save/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));

        const email = {"email": localStorage.getItem('email')}
        const token = localStorage.getItem('token');

        let cleanedToken;
        try {
            cleanedToken = token ? JSON.parse(token) : null;
        } catch {
            cleanedToken = token; // Falls kein JSON-String, einfach den ursprünglichen Wert nehmen
        }

        fetchDataApiUser('/token/user/', cleanedToken, email)
            .then((datas: any) => {
                let user
                let userRole
                if (datas[0].hasOwnProperty('name')) {
                    user = datas[0].name
                } else if (datas[0].hasOwnProperty('username')) {
                    user = datas[0].username
                }
                if (datas[0].hasOwnProperty('usergroup')) {
                    userRole = datas[0].usergroup
                } else if (datas[0].hasOwnProperty('roles')) {
                    userRole = JSON.parse(datas[0].roles)[1]
                }
                console.log(datas[0].rights)
                if(datas[0].rights !== undefined && datas[0].rights !== null && datas[0].rights !== '') {
                    localStorage.setItem('userRights', CryptEncrypt(JSON.stringify(datas[0].rights)))
                }
                if(datas[0].permission !== undefined && datas[0].permission !== null && datas[0].permission !== '') {
                    localStorage.setItem('userPermission', CryptEncrypt(JSON.stringify(datas[0].permission)))
                }
            })
            .catch((e: any) => {
                //error(e)
                localStorage.setItem('Logout', '2')
                setTimeout(
                    () => (""),
                    1000
                )
            })
    }
    const successForm = (result:any) => {
        //setFetchData(Date.now())
        notify_save(Store)
    }

    const errorForm = (error:any)=> {
        console.log(error)
    }
    const AllUSer = ResponseAllUser()
    const SetPermission = (part:any) => {



    }




    if (Profils !== true && typeof Profils !== "boolean" && Profils !== undefined && Profils.length !== 0 && localStorage.getItem('Roles') !== undefined && localStorage.getItem('AllUser') !== undefined && localStorage.getItem('Roles') !== null && localStorage.getItem('AllUser') !== null) {
        enabledFederalState({value:Profil[0].country,id:'federalState'})

        return (
            <>

                    <div className={'ticketsystem'} style={{padding: '25px'}}>
                        <Tabs
                            defaultActiveKey="profil"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="profil" title="Profil">
                                <Form
                                    id={'form001'}
                                    noValidate
                                    onSubmit={(e) =>
                                        Send(e)
                                    }
                                >
                        <UniCards class={'p-3'}>
                            <h3><IntlMessage Store={Store} messageId="profil.header"/></h3>
                            <p>
                                <IntlMessage Store={Store} messageId="profil.description"/>
                            </p>
                            <UniRow>
                                <UniCol class={'col-3 p-3'}>
                                    <FileUploadButton
                                        classNameInput={'d-none'}
                                        img={{width: '300px', height: '300px'}} defaultValue={Profil[0].media}
                                        classNameButton={'mt-3'} defaultSRC={Profil[0].media}
                                        styleButton={{width: '100%'}} placeholderImg={'/assets/avatarPlaceholder.png'}
                                        ButtonLabel={IntlMessageValue('de', 'profile_picture.button.label', Store)}
                                        DelButtonLabel={IntlMessageValue('de', 'profile_picture.delbutton.label', Store)}
                                        name={'media'}
                                        accept={"image/png, image/jpeg, image/webp"}/>
                                    <Stats
                                        preText={IntlMessageValue('de', 'valid.profil.preText', Store)}
                                        afterText={IntlMessageValue('de', 'valid.profil.afterText', Store)}
                                        validText={IntlMessageValue('de', 'valid.profil.validText', Store)}
                                        replaceName={'[PercentHolder]'} validAfterName={IntlMessageValue('de', 'valid.profil.validAfterName', Store)} validIcon={'verified_user'} ValidIconvariant={'-outlined'}
                                        item={[
                                            {title:IntlMessageValue('de', 'common.profile_picture', Store),percent:10,check: (Profil[0].media !== undefined && Profil[0].media !== null && Profil[0].media !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.responsibility', Store),percent:6,check: (Profil[0].responsibility !== undefined && Profil[0].responsibility !== null && Profil[0].responsibility !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.username', Store),percent:6,check: (Profil[0].username !== undefined && Profil[0].username !== null && Profil[0].username !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.gender', Store),percent:6,check: (Profil[0].gender !== undefined && Profil[0].gender !== null && Profil[0].gender !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.firstname', Store),percent:6,check: (Profil[0].first_name !== undefined && Profil[0].first_name !== null && Profil[0].first_name !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.lastname', Store),percent:6,check: (Profil[0].last_name !== undefined && Profil[0].last_name !== null && Profil[0].last_name !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.birthday', Store),percent:6,check: (Profil[0].birthday !== undefined && Profil[0].birthday !== null && Profil[0].birthday !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.phone', Store),percent:6,check: (Profil[0].phone !== undefined && Profil[0].phone !== null && Profil[0].phone !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.email', Store),percent:6,check: (Profil[0].phone !== undefined && Profil[0].phone !== null && Profil[0].phone !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.location', Store),percent:6,check: (Profil[0].country !== undefined && Profil[0].country !== null && Profil[0].country !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.federalState', Store),percent:6,check: (Profil[0].federalState !== undefined && Profil[0].federalState !== null && Profil[0].federalState !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.city', Store),percent:6,check: (Profil[0].city !== undefined && Profil[0].city !== null && Profil[0].city !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.department', Store),percent:6,check: (Profil[0].department !== undefined && Profil[0].department !== null && Profil[0].department !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.position', Store),percent:6,check: (Profil[0].position !== undefined && Profil[0].position !== null && Profil[0].position !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.firstStandIn', Store),percent:6,check: (Profil[0].firstStandIn !== undefined && Profil[0].firstStandIn !== null && Profil[0].firstStandIn !== '' ? true : false)},
                                            {title:IntlMessageValue('de', 'common.secondStandIn', Store),percent:6,check: (Profil[0].secondStandIn !== undefined && Profil[0].secondStandIn !== null && Profil[0].secondStandIn !== '' ? true : false)}
                                        ]} />
                                </UniCol>
                                <UniCol>
                                    <UniRow>
                                        <UniCol class={'col-4 '}>
                                        </UniCol>
                                        <UniCol class={'mcenter'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.default_language', Store)}/>
                                                <UniForm.Select defaultValue={Profil[0].locale_code} name={'locale_code'}   items={[
                                                    {value: '', text: ''},
                                                    {value: 'DE', text: IntlMessageValue('de', 'common.german', Store)},
                                                    {value: 'US', text: IntlMessageValue('de', 'common.english', Store)}
                                                ]}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                        <UniCol class={'col-4'}>
                                            <UniForm.FormControl name={'uid'} defaultValue={Profil[0].uid} required={true}
                                                                 type={'hidden'}/>
                                        </UniCol>
                                    </UniRow>
                                    <UniRow>
                                        <UniCol class={'border-right  p-3'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.responsibility', Store)}/>
                                                <UniForm.ToggleSelect defaultValue={Profil[0].responsibility} short={'responsibility'} style={{display:"block"}} id={'ResponsibilityMaker'} name={'responsibility'}
                                                                      ToggleButton={[{value:'Intern',label:'Intern'},{value:'Extern',label:'Extern'}]} />

                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.username', Store)}/>
                                                <Form.Control type="text" required={true} name={'username'} defaultValue={Profil[0].username} onKeyPress={(e)=>Regex(e,/[ A-Za-z0-9&.]/,IntlMessageValue('de', 'error.regex', Store))} />
                                            </UniForm.InputGroup>

                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.gender', Store)}/>
                                                <UniForm.ToggleSelect defaultValue={Profil[0].gender} short={'gender'} style={{display:"block"}} id={'GederMaker'} name={'gender'}
                                                                      ToggleButton={[{value:'Männlich',label:IntlMessageValue('de', 'common.gender.male', Store)},{value:'Weiblich',label:IntlMessageValue('de', 'common.gender.female', Store)},{value:'Diverse',label:IntlMessageValue('de', 'common.gender.various', Store)}]} />
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.firstname', Store)}/>
                                                <UniForm.FormControl name={'first_name'} defaultValue={Profil[0].first_name} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.lastname', Store)}/>
                                                <UniForm.FormControl name={'last_name'} defaultValue={Profil[0].last_name} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.birthday', Store)}/>
                                                <UniForm.FormControl name={'birthday'} defaultValue={Profil[0].birthday} required={true}
                                                                     type={'date'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.phone', Store)}/>
                                                <UniForm.FormControl name={'phone'} defaultValue={Profil[0].phone} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.email', Store)}/>
                                                <UniForm.FormControl name={'email'} defaultValue={Profil[0].email} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.newpassword', Store)}/>
                                                <UniForm.FormControl name={'password'} defaultValue={''} required={true}
                                                                     type={'password'}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                        <UniCol class={'p-3'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.location', Store)}/>
                                                <Country defaultValue={Profil[0].country} mode={'country'} name={'country'} className={'mb-3'} onChange={(e) => enabledFederalState({e:e,id:'federalState'})}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup id={'federalState'}>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.federalState', Store)}/>
                                                <Country defaultValue={Profil[0].federalState} mode={'federalState'} name={'federalState'} className={'mb-3'} />
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.city', Store)}/>
                                                <UniForm.FormControl name={'city'} defaultValue={Profil[0].city} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/><br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.department', Store)}/>
                                                <UniForm.FormControl name={'department'} defaultValue={Profil[0].department} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.position', Store)}/>
                                                <UniForm.FormControl name={'position'} defaultValue={Profil[0].position} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.role', Store)}/>
                                                <Form.Select  name={'discr'} className={'mb-3'} defaultValue={Profil[0].discr}>
                                                    {JSON.parse(localStorage.getItem('Roles') as string).map((route:any, index:any) =>
                                                        <option value={route.code}>{route.name}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                            <br/><br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.firstStandIn', Store)}/>
                                                <Form.Select name={'firstStandIn'} className={'mb-3'}
                                                             defaultValue={Profil[0].firstStandIn as string}>
                                                    <option
                                                        label={IntlMessageValue('de', 'common.no.second', Store)}></option>
                                                    {JSON.parse(localStorage.getItem('AllUser') as string).map((route: any, index: any) =>
                                                        <option value={route.id as string}>{route.username}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.secondStandIn', Store)}/>
                                                <Form.Select name={'secondStandIn'} className={'mb-3'}
                                                             defaultValue={Profil[0].secondStandIn as string}>
                                                    <option
                                                        label={IntlMessageValue('de', 'common.no.second', Store)}></option>
                                                    {JSON.parse(localStorage.getItem('AllUser') as string).map((route: any, index: any) =>
                                                        <option value={route.id as string}>{route.username}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                    </UniRow>
                                </UniCol>
                            </UniRow>
                            <br/><br/>

                            <UniButton type={'submit'} variant={'success'}>{IntlMessageValue('de', 'common.save', Store)}</UniButton>
                        </UniCards>
                </Form>
                    </Tab>
                        <Tab eventKey="rights" title="Rechte">
                            <Form
                                id={'form002'}
                                noValidate
                                onSubmit={(e) =>
                                    SendRights(e)
                                }
                            >
                            <UniCards class={'p-3'}>
                                <UniRow>
                                    <UniCol class={'border-right  p-3'}>
                                        <Card>
                                            <Card.Body>
                                                <h4>Ticketsystem</h4>
                                                <p>
                                                    <Form.Check
                                                        name={'Ticketsystem_Dashboard'}
                                                        type={'checkbox'}
                                                        label={`Dashboard`}
                                                        defaultChecked={Profil[0].permission.Ticketsystem.Dashboard}
                                                    />
                                                    <Form.Check
                                                        name={'Ticketsystem_Tickets'}
                                                        type={'checkbox'}
                                                        label={`Tickets`}
                                                        defaultChecked={Profil[0].permission.Ticketsystem.Tickets}
                                                    />
                                                    <Form.Check
                                                        name={'Ticketsystem_Settings'}
                                                        type={'checkbox'}
                                                        label={`Settings`}
                                                        defaultChecked={Profil[0].permission.Ticketsystem.Settings}
                                                    />
                                                    <Form.Check
                                                        name={'Ticketsystem_Reports'}
                                                        type={'checkbox'}
                                                        label={`Reports`}
                                                        defaultChecked={Profil[0].permission.Ticketsystem.Reports}
                                                    />
                                                    <Form.Check
                                                        name={'Ticketsystem_Organisation'}
                                                        type={'checkbox'}
                                                        label={`Organisation`}
                                                        defaultChecked={Profil[0].permission.Ticketsystem.Organisation}
                                                    />
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </UniCol>

                                    <UniCol class={'border-right  p-3'}>
                                        <Card>
                                            <Card.Body>
                                                <h4>Taskmanager</h4>
                                                <Form.Check
                                                    name={'TaskManager_Dashboard'}
                                                    type={'checkbox'}
                                                    label={`Dashboard`}
                                                    defaultChecked={Profil[0].permission.TaskManager.Dashboard}
                                                />
                                                <Form.Check
                                                    name={'TaskManager_Mytask'}
                                                    type={'checkbox'}
                                                    label={`Mytask`}
                                                    defaultChecked={Profil[0].permission.TaskManager.Mytask}
                                                />
                                                <Form.Check
                                                    name={'TaskManager_Projects'}
                                                    type={'checkbox'}
                                                    label={`Projects`}
                                                    defaultChecked={Profil[0].permission.TaskManager.Projects}
                                                />
                                                <Form.Check
                                                    name={'TaskManager_Reports'}
                                                    type={'checkbox'}
                                                    label={`Reports`}
                                                    defaultChecked={Profil[0].permission.TaskManager.Reports}
                                                />
                                                <Form.Check
                                                    name={'TaskManager_ServiceTracking'}
                                                    type={'checkbox'}
                                                    label={`ServiceTracking`}
                                                    defaultChecked={Profil[0].permission.TaskManager.ServiceTracking}
                                                />
                                                <Form.Check
                                                    name={'TaskManager_Settings'}
                                                    type={'checkbox'}
                                                    label={`Settings`}
                                                    defaultChecked={Profil[0].permission.TaskManager.Settings}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </UniCol>
                                </UniRow>
                                <UniRow>
                                    <UniCol>
                                        <Card>
                                            <Card.Body>
                                                <h4>Allgemein</h4>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    name={'General_Comapny'}
                                                    label={`Comapny`}
                                                    defaultChecked={Profil[0].permission.General.Comapny}
                                                />
                                                <Form.Check
                                                    type={'checkbox'}
                                                    name={'General_PostOffice'}
                                                    label={`PostOffice`}
                                                    defaultChecked={Profil[0].permission.General.PostOffice}
                                                />
                                                <Form.Check
                                                    type={'checkbox'}
                                                    name={'General_stats'}
                                                    label={`stats`}
                                                    defaultChecked={Profil[0].permission.General.stats}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </UniCol>
                                </UniRow>
                                <UniButton type={'submit'} variant={'success'}>{IntlMessageValue('de', 'common.save', Store)}</UniButton>
                            </UniCards>
                            </Form>
                        </Tab>
                    </Tabs>
                    </div>

            </>
    )
    } else {
        return (
            <UniSpinner text={'Loading...!'} variant={'secondary'}/>
        )
    }
    }

    export default MemberDetail
