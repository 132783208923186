import React, {useEffect, useRef, useState} from 'react'
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner'
import {useNavigate, useParams} from 'react-router-dom'
import {
    Response,
    ResponseAllMerchant,
    ResponseAllSRoles,
    ResponseDelete
} from '../API/response/member/member'
import {memberApiType} from '../API/request/member/memberApi.interface'
import {IntlMessage, IntlMessageValue, ApiURL, ContentSwitch, SearchJSX} from '../../../../@WUM/core/Elements/index'
import {handleSucces, getToken, setNavi} from '../../../../@WUM/core/Function/index'
import {useSelector} from 'react-redux'
import PaginationJSX from "../../../../@WUM/core/form-serilizer/elements/pagination";
import TableMaker from "../../../../@WUM/core/tablemaker";
import {CryptDecrypt, CryptEncrypt} from "../../../../@WUM/core/Elements/crypt/Crypt";
import {refresh} from "../../../ticketsystem/modules/new/Pages/SingleTask";
import {Row, Col, Card} from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import '../css/member.css'
import {fetchDataApiUser} from "../../../../@WUM/core/RestFullApi/ApiHook";

const MemberOverview = (value: any) => {
    const {page, deleteID} = useParams<{ page: string; deleteID: string }>()
    setNavi({
        route: '/Member/overview/1'
    })
    const [fetchData, setFetchData] = useState(0);
    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const Store = useSelector((state: any) => state)
    /*const MerchantSelect = () => {
        const [issue, setIssue] = useState([]) // Array instead of object

        React.useEffect(() => {
            let root = ApiURL()
            const fetchData = async () => {
                const result = await fetch(root + `User/byMerchant/`, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                    .then((response) => response.json())
                    .then((response) => {
                        return response
                    })
                    .catch((err) => console.error(err))

                setIssue(result)
            }

            fetchData()
        }, [])

        return issue
    }*/

    const history = useNavigate()
    /**
     * Reload Page
     */
    const routeChange = (paths: string) => {
        history(paths)
    }

    if (deleteID) {
        ResponseDelete(deleteID)
        routeChange('/Member/overview/1')
    }
    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState<boolean>(false)
    const response = Response(
        limit,
        searchValue !== '?search=1&' ? searchValue : '',
        fetchData
    )
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    /*const AllMerchant = ResponseAllMerchant()*/
    const Roles = ResponseAllSRoles()
    const debug = (
        searchValue: string,
        page: string | undefined,
        Roles: memberApiType[] | boolean,
        modalShow: any,
        modalData: any
    ) => {
        return
    }
    debug(searchValue, page, Roles, modalShow, modalData)

    const _handleEnter = function (e: any) {
        if (e.key === 'Enter') {
            let search = ''
            let title
            let iterator = []

            title = e.target.value
            localStorage.setItem('searchInput', title)
            if (title !== undefined && title !== '') {
                iterator.push('email=' + title)
            }
            let discriminator
            discriminator = (
                document.getElementById('searchSelect') as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelect', discriminator)
            if (discriminator !== undefined && discriminator !== '') {
                iterator.push(discriminator)
            }

            for (let y = 0; y < iterator.length; y++) {
                if (y === iterator.length - 1) {
                    search += iterator[y]
                } else {
                    search += iterator[y] + '&'
                }
            }
            setSearchValue('?search=1&' + search)
        }
    }

    const _handleKeyFilters = function (e: any) {
        let discriminator
        let title
        let merchant
        if (e.target.value !== '0') {
            let search = ''

            let iterator = []

            title = (document.getElementById('search') as HTMLInputElement)
                .value
            localStorage.setItem('searchInput', title)
            if (title !== undefined && title !== '') {
                iterator.push('email=' + title)
            }

            discriminator = (
                document.getElementById('searchSelect') as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelect', discriminator)
            if (discriminator !== undefined && discriminator !== '') {
                iterator.push(discriminator)
            }

            merchant = (
                document.getElementById(
                    'searchSelectMerchant'
                ) as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelectMerchant', merchant)
            if (merchant !== undefined && merchant !== '') {
                iterator.push('merchant=' + merchant)
            }

            for (let y = 0; y < iterator.length; y++) {
                if (y === iterator.length - 1) {
                    search += iterator[y]
                } else {
                    search += iterator[y] + '&'
                }
            }
            setSearchValue('?search=1&' + search)
        }
    }

    const searchable = [
        {
            param: {
                placeholder: 'user.search'
            }
        },
        {
            option: {
                value: 'discr=admin',
                label: 'role.superadmin'
            }
        },
        {
            option: {
                value: 'discr=channel',
                label: 'role.admin'
            }
        },
        {
            option: {
                value: 'discr=operator',
                label: 'role.operator'
            }
        },
        {
            option: {
                value: 'discr=author',
                label: 'role.author'
            }
        },
        {
            option: {
                value: 'discr=content-creator',
                label: 'role.content_creator'
            }
        },
        {
            option: {
                value: 'discr=merchant',
                label: 'role.provider'
            }
        },
        {
            option: {
                value: 'discr=merchant_ma',
                label: 'role.employee'
            }
        }
    ]

    /* let merchant = MerchantSelect()*/

    const [mode, setMode] = useState('cart')
    const destination = 'Member'

    const CardsConfiguration = {
        data: {
            ressource: response,
            img: '',
            status: 'is_active',
            statusActive: IntlMessageValue('de', 'common.activated', Store),
            statusDeactive: IntlMessageValue('de', 'common.deactivated', Store),
            link: 'slug',
            prelink: '',
            prelinksearch: 'translation.slug',
            multiTitle: true,
            Titles: ['first_name', 'last_name'],
            title: 'username',
            descriptionShow: true,
            description: 'group',
            description2: 'merchant_company',
            router: 'id',
            preroute: '/Member/Detail/',
            edit: IntlMessageValue('de', 'user.edit', Store),
            delete: true,
            deleteLink: '/Member/Delete/' + page + '/[ID]',
            Dontvisited: true
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['is_active', 'email', 'first_name', 'last_name', 'discr'],
            type: ['status', 'string', 'string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.status"/>,
            <IntlMessage Store={Store} messageId="common.email"/>,
            <IntlMessage Store={Store} messageId="common.name.first"/>,
            <IntlMessage Store={Store} messageId="common.name.last"/>,
            <IntlMessage Store={Store} messageId="permissions"/>
        ],
        tableHeaderWidth: ['20%', '20%', '20%', '20%', '20%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit"/>,
                <IntlMessage Store={Store} messageId="common.delete"/>
            ],
            menuroute: ['/Member/Detail', '/Member/Delete'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
    const namespace_search = 'Member_searchValue'
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_emailr',
                    translation: 'common.email',
                    param: 'email',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'user.create'
            ]
        }
    ]

    const [maxHeight, setMaxHeight] = useState(0); // Initialize with 0
    const [load, setLoad] = useState(0)
    useEffect(() => {
        const heights = [
            ((document.getElementById('targetDivHeightT2') as HTMLDivElement)?.clientHeight || 0), // Check for null and use 0 as default
            ((document.getElementById('targetDivHeightT') as HTMLDivElement)?.clientHeight || 0),
            ((document.getElementById('targetDivHeightL') as HTMLDivElement)?.clientHeight || 0),
            ((document.getElementById('targetDivHeightA') as HTMLDivElement)?.clientHeight || 0)
        ];

        const newMaxHeight = Math.max(...heights);

        console.log(heights)
        if (newMaxHeight !== maxHeight) {
            setMaxHeight(newMaxHeight);
        }

    }, [maxHeight, load]);




    useEffect(() => {
        const email = {"email": localStorage.getItem('email')}
        const token = localStorage.getItem('token');

        let cleanedToken;
        try {
            cleanedToken = token ? JSON.parse(token) : null;
        } catch {
            cleanedToken = token; // Falls kein JSON-String, einfach den ursprünglichen Wert nehmen
        }
        fetchDataApiUser('/token/user/', cleanedToken, email)
            .then((datas: any) => {
                if (datas[0].rights !== undefined && datas[0].rights !== null && datas[0].rights !== '') {
                    localStorage.setItem('userRights', CryptEncrypt(JSON.stringify(datas[0].rights)))
                }
                if (datas[0].permission !== undefined && datas[0].permission !== null && datas[0].permission !== '') {
                    localStorage.setItem('userPermission', CryptEncrypt(JSON.stringify(datas[0].permission)))
                }
            })
            .catch((e: any) => {
                //error(e)
                localStorage.setItem('Logout', '2')
                setTimeout(
                    () => (""),
                    1000
                )
            })
    }, []);


    if (response !== true && response.length > 0 && localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined) {
        let Rigths = JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string)

        if (load === 0) {
            setLoad(1)
        }


        return (
            <>

                <Row className={'mx-auto'}>
                    <Col><BuildCards id={'targetDivHeightT2'}
                                     TextStyle={{
                                         height: (maxHeight === 0 ? 'auto' : maxHeight),
                                         backgroundImage: 'url("/group.png")'
                                     }}
                                     style={{background: '#722DA1', color: 'white'}} response={Rigths}
                                     title={'TICKET SYSTEM 2.0'} user={'users'}
                                     usercurrent={'usersCurrentTokenTicketsystem'} usermax={'usersLimitTicketsystem'}
                                     active={true}/></Col>
                    <Col><BuildCards id={'targetDivHeightT'}
                                     TextStyle={{
                                         height: (maxHeight === 0 ? 'auto' : maxHeight),
                                         backgroundImage: 'url("/group.png")'
                                     }}
                                     style={{background: '#9B259D', color: 'white'}} response={Rigths}
                                     title={'TASK MANAGER'} user={'usersTaskmanager'}
                                     usercurrent={'usersCurrentTokenTaskmanager'} usermax={'usersLimitTaskmanager'}
                                     active={true}/></Col>
                    <Col><BuildCards id={'targetDivHeightL'} TextStyle={{
                        height: (maxHeight === 0 ? 'auto' : maxHeight),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: 'url("/group.png")'
                    }} style={{background: '#3832A0', color: 'white'}} response={Rigths} title={'LEISTUNGSERFASSUNG'}
                                     user={''} usercurrent={''} usermax={''} active={false}/></Col>
                    <Col><BuildCards id={'targetDivHeightA'} TextStyle={{
                        height: (maxHeight === 0 ? 'auto' : maxHeight),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: 'url("/group.png")'
                    }} style={{background: '#3EA0DF', color: 'white'}} response={Rigths} title={'ARBEITSZEITERFASSUNG'}
                                     user={''} usercurrent={''} usermax={''} active={false}/></Col>
                </Row>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    noNewButton={(response[0].CanCreate !== undefined && response[0].CanCreate === 1 ? false : (response[0].CanCreate !== undefined && response[0].CanCreate === 0 ? true : false))}
                    SearchConfig={SearchConfig}
                    reloadButton={refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}
                />
                <TableMaker Configuration={Configuration}/>
                <PaginationJSX
                    response={response}
                    history={history}
                    to={'overview'}
                    handleSuccess={handleSucces}
                    destination={destination}
                />
                {/*<ContentSwitch
                    pagenew={'new'}
                    destinationnew={'Member'}
                    tonew={'new'}
                    text={<IntlMessage Store={Store} messageId="user.create" />}
                    response={response}
                    CardsConfiguration={CardsConfiguration}
                    mode={mode}
                    Configuration={Configuration}
                    setMode={setMode}
                    destination={destination}
                    page={page}
                    to={'overview'}
                    setSearchValues={setSearchValue}
                    setSearchs={setSearch}
                    search={search}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalData={modalData}
                    setModalData={setModalData}
                    searchfield={searchable}
                    allTaxon={''}
                    _handleKeyFilters={_handleKeyFilters}
                    _handleEnter={_handleEnter}
                    filter={false}
                    merchantSelect={[]}
                    Store={Store}
                    SearchParam={'?email'}
                />*/}
            </>
        )
    } else {
        localStorage.setItem('searchInput', '')
        localStorage.setItem('searchSelect', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store}/>
            </>
        )
    }
}

export default MemberOverview


const BuildCards = (props: {
    response: any,
    id: any,
    title: any,
    user: any,
    usercurrent: any,
    usermax: any,
    active: any,
    style?: any,
    TextStyle?: any
}) => {

    return (
        <Card style={{width: '100%'}}>
            <Card.Body className={'text-center m-0 pb-0 pl-0 pr-0'} style={props.style}>
                <Card.Title>{props.title}<br/>
                    <hr/>
                </Card.Title>

                <Card.Text id={props.id}
                           style={props.TextStyle} className={'BackgroundBox'}
                >
                    {props.active !== false ?
                        <>
                            <p>Aktive Benutzer<br/>
                                {props.response[props.user]} / {props.response[props.usercurrent]}<br/>
                                Verfügbare Benutzer-Credits<br/>
                                {props.response[props.usercurrent] - props.response[props.user]}<br/>
                                Maximale Benutzer<br/>
                                {props.response[props.usermax]}</p>
                        </>
                        :
                        <>
                            <p>Dieses Modul ist derzeit nicht aktiv</p>
                        </>
                    }
                </Card.Text>
                <Card.Footer className="bg-black text-white">

                    {props.active !== false ? (
                        <Button variant="dark" className="bg-black text-white border-0" type={'button'} href={'#'}>
                            BENUTZER-CREDITS KAUFEN
                        </Button>
                    ) : (
                        <Button variant="dark" className="bg-black text-white border-0" type={'button'} href={'#'}>
                            MODUL KOSTENLOS BUCHEN
                        </Button>
                    )}

                </Card.Footer>

            </Card.Body>
        </Card>
    )
}
